import React, { useEffect, useState } from "react";
import "./App.scss";

import { ReactComponent as Instagram } from "./svg/instagram.svg";
import { ReactComponent as Facebook } from "./svg/facebook.svg";
import ArtistsOverview from "./Components/ArtistsOverview/ArtistsOverview";
import TicketSale from "./Components/TicketSale/TicketSale";
import Header from "./Components/Header/Header";

function App() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isUserScrolled = window.scrollY > 100;
            setIsScrolled(isUserScrolled);
        };

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Cleanup function
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="app">
            <div id="top" />
            <Header isScrolled={isScrolled} />
            <div className="main-container container text-center">
                <div className="row" id="intro">
                    <div className="col pb-5 mb-5">
                        <img src="./images/logo-feestival-2000.png" alt="Feestival Deurne" className="logo mb-5" />
                        <h1>Weekend vol feest!</h1>
                        <p className="mb-5">
                            Wie jarig is, trakteert. Aldus Leon van Osch, voorzitter van de commissie die de feestiviteiten rond het 66-jarige bestaan van carnavalsstichting De Peelstrekels
                            coördineert. In het laatste weekend van september, op vrijdag 27, zaterdag 28 en zondag 29 september staat het hart van Deurne op zijn kop met feestiviteiten voor jong en
                            oud. Op vrijdagavond komt de jeugd tot en met 17 jaar aan hun trekken in uitgaansgelegenheid The Ocean, zaterdagavond is er in de grote tent op de Markt het Feestival met
                            tal van bekende Nederlandse artiesten en op zondagmiddag, ook in de grote tent, is er muziek, onder andere van blaaskapellen.
                        </p>
                        <a href="#tickets" className="btn btn-yellow">
                            Tickets zaterdag
                        </a>
                    </div>
                </div>
                <div className="row" id="ocean-feestival">
                    <div className="col">
                        <h2 className="mb-0">Feestival Club Night @ The Ocean</h2>
                        <h3 className="mb-3">Vrijdag 27 september</h3>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-4 mb-3 mb-md-0">
                        <img src="./images/feestival-club-night.jpg" alt="Feestival Club Night @ The Ocean" className="img-fluid" />
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 offset-md-0 col-md-4 mb-3 mb-md-0">
                        <img src="./images/jesse-hoefnagels-rhode-kok.jpg" alt="Jesse Hoefnagels & Rhode Kok" className="img-fluid" />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col pb-3">
                        <p>
                            Jesse Hoefnagels & Rhodé Kok komen op vrijdag 27 september speciaal voor de Feestival club night naar The Ocean! Ook komen DJ Djensse & Mc Ruffess en DJ EIM ook langs voor
                            devetste muziek en is er een photo wall. Een ticket kost 5 euro inclusief onbeperkt drankjes.
                        </p>
                        <h4>Wat moet je weten:</h4>
                        <ul className="col col-md-6 offset-md-3 text-start">
                            <li>Leeftijd 12 t/m 17 (middelbare scholieren)</li>
                            <li>NIX18 —&gt; er wordt uiteraard geen alcohol geschonken</li>
                            <li>Open vanaf 19:30 tot 23:00</li>
                            <li>Je kunt binnen tot 20.30 en de deuren gaan weer open vanaf 22.30</li>
                            <li>5 euro inclusief drankjes</li>
                            <li>Legitimatie verplicht!</li>
                        </ul>
                        <p>Wees er snel bij want op = op!</p>
                        <a href="https://www.tickets-deurne.nl/event?Gr_Id=73" className="download-presskit btn btn-yellow" target="_blank" rel="noreferrer">
                            Koop je tickets op Tickets-Deurne.nl
                        </a>
                    </div>
                </div>
                <div className="row" id="feestival-deurne">
                    <div className="col pb-3">
                        <h2 className="mb-0">Feestival Deurne</h2>
                        <h3 className="mb-3">Zaterdag 28 september</h3>
                        <p>
                            De zaterdagavond is het hoogtepunt van de Feestiviteiten; in een doorlopend en avondvullend programma – wees op tijd om niets te missen – dat om stipt 19.00 uur begint
                            trekt een keur aan artiesten voorbij en presentator Frans Feest loodst de feestvierenden door de bomvolle avond heen. Een kaartje kost 15 euro in de voorverkoop en op de
                            avond zelf betaal je 20 euro. De line up biedt 100% non-stop feestgarantie van 19.00 tot 1.00 uur.
                        </p>
                    </div>
                </div>
                <ArtistsOverview />
                <div className="anchor" id="tickets" />
                <div className="row">
                    <div className="col pb-3">
                        <h2>Tickets</h2>
                    </div>
                </div>
                <TicketSale />
                <div className="row" id="jubileumfeest-de-peelstrekels">
                    <div className="col pb-3">
                        <h2 className="mb-0">Jubileumfeest de Peelstrekels</h2>
                        <h3 className="mb-3">Zondag 29 september</h3>
                        <p>
                            Op zondag nodigen de Peelstrekels iedereen uit die ooit iets heeft betekend voor carnaval in Deurne! Om 13.00 gaat de tent open en volgen er optredens van o.a. de KHD
                            Deurne, dat dit jaar zijn 150 jarig jubileum viert, Marlon Kicken, een cantusband, John Tana en Moonlight! De entree is gratis.
                        </p>
                    </div>
                </div>
            </div>
            <div id="footer" className="mt-auto mb-1 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col mb-2">
                            <strong>Op de hoogte blijven?</strong>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col text-center gap-4 d-flex justify-content-center align-items-center">
                            <a href="https://www.facebook.com/feestivaldeurne" className="social-link facebook" target="_blank" rel="noreferrer">
                                <Facebook />
                            </a>
                            <a href="https://www.instagram.com/feestivaldeurne" className="social-link instagram" target="_blank" rel="noreferrer">
                                <Instagram />
                            </a>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col text-center gap-4 d-flex justify-content-center align-items-center">
                            <a href="/downloads/Feestival-Presskit.zip" className="download-presskit btn btn-yellow" download>
                                Download Presskit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
