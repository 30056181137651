import "./TicketSale.scss";

const TicketSale = () => {
    return (
        <div className="ticket-sale mb-5 row">
            <div className="col-12  pb-3">Kaarten zijn in de voorverkoop verkrijgbaar voor € 15,- per stuk. Aan de deur kosten de kaarten € 20,- per stuk.</div>
            <div className="col-xl-8 offset-xl-2 pb-5">
                <iframe title="Feestival Ticket Verkoop" src="https://shop.ikbenaanwezig.nl/tickets/event/feestival?clean_iframe=on" />
            </div>
        </div>
    );
};

export default TicketSale;
